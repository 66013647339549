<template>
	<div class="svg-icon" v-if="svgDataUrl" :style="{ backgroundColor: bgColor, width: '64px', height: '64px' }">
		<img :src="svgDataUrl" alt="SVG Icon" />
	</div>
</template>

<script setup>
	import { ref, onMounted } from 'vue';

	const props = defineProps({
		src: String, // The path to your SVG file
		bgColor: {
			type: String,
			default: 'transparent',
		},
	});

	const svgDataUrl = ref('');

	onMounted(async () => {
		if (props.src) {
			try {
				const response = await fetch(props.src);
				const content = await response.text();
				const sanitizedContent = content.replace('<svg', '<svg preserveAspectRatio="xMidYMid meet"');
				svgDataUrl.value = 'data:image/svg+xml;base64,' + btoa(sanitizedContent);
			} catch (err) {
				console.error('Error loading SVG:', err);
			}
		}
	});
</script>

<style scoped lang="scss">
	.overlay-icon img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.overlay-icon {
		position: absolute;
		top: -16px;
		left: -16px;
		opacity: 1;
		width: 64px;
		height: 64px;
		z-index: 1;
	}
</style>
